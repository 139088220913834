import { useStaticQuery, graphql } from "gatsby"

const useNavigationImages = () => {
  const data = useStaticQuery(graphql`
    {
      capsule: file(relativePath: { eq: "navigations/capsule.png" }) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      tablet: file(relativePath: { eq: "navigations/pills.png" }) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      document: file(relativePath: { eq: "navigations/paper.png" }) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return data
}

export default useNavigationImages
