import React from "react"

import Container from "../../Layout/Container"
import CardButtons from "../../Elements/CardButtons"

import { pageNavigations } from "./utils/navigation"
import { ProgramName, Brand } from "../../Elements/Brand"

const PageNavigation = ({ setView }) => {
  return (
    <Container isCentered desktop={10} fullhd={8}>
      <div className="container has-text-centered fade-to-right">
        <h2>
          Welcome to the <ProgramName />
        </h2>
        <h5 className="subtitle">
          The <ProgramName /> is a patient support program from{" "}
          <Brand type="company" />. <br className="is-hidden-mobile" />
          To proceed, select the assistance you would like to apply for:
        </h5>
        <CardButtons buttons={pageNavigations({ setView })} />
      </div>
    </Container>
  )
}

export default PageNavigation
