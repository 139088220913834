import {
  GATSBY_SITE_NAVIGATION_DS,
  GATSBY_SITE_NAVIGATION_HOPE,
  GATSBY_SITE_NAVIGATION_GIOTRIF,
} from "gatsby-env-variables"

import { navigate } from "gatsby"

export const pageNavigations = ({ setView }) => {
  return [
    {
      label: "Nintedanib (Ofev) medicine assistance",
      img: "capsule",
      onClick: () => {
        sessionStorage.setItem(
          "site",
          JSON.stringify({ code: GATSBY_SITE_NAVIGATION_HOPE })
        )
        setView("HOPE_VIEW")
      },
    },
    {
      label: "Afatinib (Giotrif) medicine assistance",
      img: "tablet",
      onClick: () => {
        sessionStorage.setItem(
          "site",
          JSON.stringify({ code: GATSBY_SITE_NAVIGATION_GIOTRIF })
        )
        navigate("/giotrif")
      },
    },
    {
      label: "High Resolution Computed Tomography (HRCT) diagnostic subsidy",
      img: "document",
      onClick: () => {
        sessionStorage.setItem(
          "site",
          JSON.stringify({ code: GATSBY_SITE_NAVIGATION_DS })
        )
        navigate("/diagnostic-subsidy")
      },
    },
  ]
}
